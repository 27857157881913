@mixin __FF_HISTORICAL_ORDERS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_USE_CVM_TEST_DATA($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ONE_REACH_MOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_RATES_AND_RULES_INFO__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DTCC_INLINE_APPLY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_EVENTSTREAM__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_RESERVATION_DETAILS__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_DRIVER_TYPES_UPDATE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DEBUG_CEC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_BOPIS_TO_QUOTE_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_HISTORICAL_ORDER_EDIT_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_LEGACY_ORDER_ACTIONS_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_RETURN_ALL_BLOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base.scss';

%button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  max-width: 335px;
  min-width: 165px;
  min-height: 50px;
  font-size: rem(16);
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  background: $dt-light-medium-gray;
  color: $dt-medium-gray;
  cursor: pointer;
  border-radius: 3px;
  transition: background 0.4s ease-in;

  &:hover,
  &:active,
  &:focus {
    color: $dt-medium-gray;
    background: $dt-light-gray;
  }

  &:disabled {
    background: $dt-medium-light-gray;
    color: $medium-gray;
    border: none;

    &:hover,
    &:active,
    &:focus {
      background: $dt-medium-light-gray;
      color: $medium-gray;
      cursor: not-allowed;
    }
  }
}

.tertiary {
  @extend %button;
}

.secondary {
  @extend %button;
  padding: 14px;
  font-weight: 800;
  line-height: rem(19);
  border: 1px solid $dt-red;
  background: $white;
  color: $dt-red;

  &:hover,
  &:active,
  &:focus {
    color: $dt-red;
    background: $white;
  }
}

.primary {
  @extend %button;
  background: $dt-red;
  font-weight: 800;
  color: $white;

  &:hover,
  &:active,
  &:focus {
    color: $white;
    background: darken($dt-race-red, 4%);
  }
}

.default {
  background: transparent;
  display: inline-block;
  padding: 0;
  font-size: rem(14);
  color: $dt-blue;

  &:disabled {
    cursor: auto;
    color: $medium-gray;
  }
}

.full-width {
  width: 100%;
}

.icon {
  margin-right: 8px;
}
