@mixin __FF_HISTORICAL_ORDERS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_USE_CVM_TEST_DATA($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ONE_REACH_MOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_RATES_AND_RULES_INFO__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DTCC_INLINE_APPLY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_EVENTSTREAM__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_RESERVATION_DETAILS__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_DRIVER_TYPES_UPDATE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DEBUG_CEC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_BOPIS_TO_QUOTE_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_HISTORICAL_ORDER_EDIT_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_LEGACY_ORDER_ACTIONS_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_RETURN_ALL_BLOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../shared/scss/dt-base.scss';

%option {
  padding: 12px 10px;
  background: transparent;
  color: $dark-gray;
  font-weight: 700;

  @include media($min-lg) {
    padding: 12px;
  }
}

%popover {
  top: 52px;

  @include media($min-sm) {
    display: block;
  }
}

.wrapper {
  position: fixed;
  width: 100%;
  background-color: $white;
  border-bottom: 1px solid $dt-nav-light-gray;
  z-index: 10;
}

.wrapper--extended-links {
  height: 164px;
}

.container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 90px;
  margin: 0 auto;
  max-width: 1720px;

  @include media($min-sm) {
    height: 112px;
    padding: 0 20px;
  }

  @include media($min-md) {
    padding: 0 40px;
  }

  @include media($min-xl) {
    padding: 0 60px;
  }
}

.container--bottom-border {
  border-bottom: 1px solid $cec-light-gray;
}

.mini-cart-popover {
  @extend %popover;
  width: 335px;
}

.mini-cart-empty-popover {
  @extend %popover;
  width: 300px;
}

.my-store-popover {
  @extend %popover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 240px;
}

.my-account-popover {
  @extend %popover;
  right: 10px;
  width: 183px;
}

.my-account-popover-list {
  width: 100%;
}
.my-account-list-item {
  &:first-child {
    margin: 0;
    color: $medium-gray;
    background: $dt-light-medium-gray;
  }
  padding: 16px 5px;
  font-size: 0.875rem;
  text-align: center;
  border-bottom: 1px solid $light-gray;
}

.site-logo {
  display: block;
  flex-shrink: 0;
  width: 75px;

  @include media($min-sm) {
    width: 150px;
    padding-right: 30px;
  }

  @include media($min-md) {
    width: 180px;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.nav {
  flex: 1 0;
  height: 100%;
}

.nav-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  min-height: 40px;
  border-bottom: 1px solid $dt-nav-light-gray;

  @include media($min-sm) {
    padding: 0;
    height: 50%;
  }
}

.nav-section--no-border {
  border: 0;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-link {
  @extend %option;
  font-size: rem(11);
  text-transform: uppercase;
  display: flex;
  align-items: center;

  @include media($min-md) {
    font-size: rem(12);
  }
}

.menu-button {
  @extend %option;
  text-transform: uppercase;
  margin: 4px 0;
  font-size: rem(14);
  font-weight: 700;
}

.menu-icon {
  margin-right: 3px;
  font-size: rem(13);
}

.arrow-icon {
  margin-left: 8px;
  font-size: rem(18);
  color: $medium-gray;
  pointer-events: none;
}

.marker-icon {
  color: $dt-red;
  font-size: rem(15);
  margin-right: 5px;
}

.user-icon {
  font-size: rem(16);
  color: $dt-blue;
}

.nav-buttons {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  width: 82px;
  margin-left: -10px;
  padding-right: 10px;

  @include media($min-sm) {
    width: auto;
    margin: 0 0 0 auto;
    padding-right: 0;
  }

  button::before {
    @include media($min-sm) {
      content: '';
      position: absolute;
      left: 0px;
      height: 22px;
      width: 1px;
      background: $dt-nav-light-gray;
    }
  }

  .myaccount-link {
    color: $dt-blue;
  }
  .myaccount-link::before {
    display: none;
  }
  .myaccount-link::after {
    content: '';
    position: absolute;
    right: 0px;
    height: 22px;
    width: 1px;
    background: $dt-nav-light-gray;
  }
}

.nav-button {
  display: flex;
  align-items: center;
  position: relative;
  @extend %option;
  font-size: rem(14);
  white-space: nowrap;

  @include media($min-sm) {
    font-weight: 600;
    font-size: rem(13);
    text-transform: none;
  }

  & > * {
    pointer-events: none;
  }
}

.nav-notification {
  position: absolute;
  top: 9px;
  margin-left: 13px;

  @include media($min-sm) {
    position: static;
    margin-left: 5px;
  }
}

.sign-in {
  flex-shrink: 0;
  margin-left: 7px;
  font-weight: 700;
}

.feature-label {
  flex-shrink: 0;
  margin-left: 7px;
  font-weight: 500;
}

.my-store-address {
  max-width: 21vw;
  color: $dt-medium-gray;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @include media($min-md) {
    max-width: 27vw;
  }
}

.skip {
  position: absolute;
  top: -10000px;
}

.search-placeholder {
  margin-right: 15px;
  color: $dt-medium-gray;
  font-weight: 500;
}

.user-popover {
  &:global(__pointer) {
    background: $dt-light-medium-gray;

    @include media($min-sm) {
      background: $white;
    }
  }
}

.greeting {
  color: $dark-gray;
}

.user-options {
  width: 185px;

  li {
    margin: 0 10px;
    padding: 16px 5px;
    font-size: 0.875rem;
    text-align: center;
    border-bottom: 1px solid $light-gray;
  }

  .mobile-greeting {
    background: $dt-light-medium-gray;
    margin: 0;
  }
}

.store-summary {
  display: flex;
  line-height: 15px;
  font-size: rem(13);
}
.store-number {
  display: flex;
  padding-left: 0;
  font-size: rem(12);
}

.store-description {
  display: flex;
  flex-direction: column;
  margin-left: -12px;
  margin-right: 50px;
}
.detailed-info {
  margin-left: 14px;
  font-size: rem(13);
  line-height: rem(13);
}
.detailed-info--address {
  text-transform: capitalize;
}

.my-store-nav-button {
  display: flex;
  align-items: center;
  position: relative;
  font-size: rem(14);
  white-space: nowrap;

  @include media($min-sm) {
    font-weight: 600;
    font-size: rem(13);
    text-transform: none;
  }
}
