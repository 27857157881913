@mixin __FF_HISTORICAL_ORDERS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_USE_CVM_TEST_DATA($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ONE_REACH_MOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_RATES_AND_RULES_INFO__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DTCC_INLINE_APPLY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_EVENTSTREAM__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_RESERVATION_DETAILS__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_DRIVER_TYPES_UPDATE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DEBUG_CEC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_BOPIS_TO_QUOTE_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_HISTORICAL_ORDER_EDIT_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_LEGACY_ORDER_ACTIONS_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_RETURN_ALL_BLOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../shared/scss/dt-base.scss';

.container {
  display: none;
  position: absolute;
  top: 91px;
  left: 0;
  width: 100%;
  padding: 25px 25px 35px;
  background: $white;
  font-size: rem(12);
  font-weight: 500;
  border: 1px solid $dt-nav-light-gray;
  border-top: 0;

  @include media($min-sm) {
    display: flex;
    top: 112px;
  }

  @include media($min-md) {
    left: 192px;
  }

  @include media($min-lg) {
    width: 950px;
  }
}

.container--extended-links {
  top: 162px;
}

.main-link {
  display: block;
  width: 150px;
  margin-top: 10px;
  padding: 11px;
  background: $dt-light-medium-gray;
  text-transform: uppercase;
  text-align: center;
  color: $dt-medium-gray;
}

.category {
  margin: 0px 10px 15px 40px;
}

.category-name {
  display: block;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 500;
  white-space: nowrap;
}

.category-list-link {
  display: block;
  margin-bottom: 10px;
  color: $dt-medium-gray;
}

.view-all-link {
  display: block;
  margin-top: 18px;
  color: $dt-blue;
}

.category-links {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @include media($min-lg) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 750px;
  }
}
