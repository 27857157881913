@mixin __FF_HISTORICAL_ORDERS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_USE_CVM_TEST_DATA($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ONE_REACH_MOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_RATES_AND_RULES_INFO__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DTCC_INLINE_APPLY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_EVENTSTREAM__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_RESERVATION_DETAILS__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_DRIVER_TYPES_UPDATE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DEBUG_CEC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_BOPIS_TO_QUOTE_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_HISTORICAL_ORDER_EDIT_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_LEGACY_ORDER_ACTIONS_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_RETURN_ALL_BLOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base.scss';

:global input:invalid {
  box-shadow: none;
}

.default {
  position: relative;
  border-radius: 3px;
  width: 100%;
}

.input-container {
  height: 50px;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid $medium-gray;
  background: $white;
}

.input-container-error {
  @extend .input-container;
  border-color: $orange;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.input-container-info {
  @extend .input-container;
  border-color: $dt-blue;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.input-container--disabled {
  border-color: $medium-light-gray;
}

.messages {
  min-width: 120px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: rem(14);
  font-weight: 600;
  overflow: hidden;
}

.error-message {
  margin: 0;
  padding: 10px 20px 14px;
  background: $orange;
  color: $dark-gray;
}

.info-message {
  margin: 0;
  padding: 10px 20px 14px;
  background: $dt-blue;
  color: $white;
}

.icon {
  margin-right: 10px;
  color: $white;
}

:global .react-selectize.root-node .react-selectize-control {
  position: relative;
  display: flex;
  cursor: pointer;

  .react-selectize-toggle-button-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 34px;
    height: 48px;
    cursor: pointer;
    z-index: 1;
  }
}

:global
  .react-selectize.default.root-node
  .react-selectize-search-field-and-selected-values {
  display: flex;
  flex-grow: 1;
  padding: 11px 0 13px 20px;
  font-weight: 600;
  z-index: 1;

  .resizable-input {
    font-weight: 600;
    font-size: rem(16);
  }
}

.input-label-disabled {
  &::before {
    display: none;
  }
}
