@mixin __FF_HISTORICAL_ORDERS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_USE_CVM_TEST_DATA($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ONE_REACH_MOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_RATES_AND_RULES_INFO__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DTCC_INLINE_APPLY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_EVENTSTREAM__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_RESERVATION_DETAILS__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_DRIVER_TYPES_UPDATE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DEBUG_CEC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_BOPIS_TO_QUOTE_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_HISTORICAL_ORDER_EDIT_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_LEGACY_ORDER_ACTIONS_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_RETURN_ALL_BLOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../shared/scss/dt-base.scss';

.wrapper {
  position: fixed;
  width: 100%;
  border-bottom: 1px solid $dt-nav-light-gray;
  background: $white;
  z-index: 10;
}

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 11px 20px;
  max-width: $content-max-width-xl;

  @include media($min-sm) {
    padding: 11px 40px;
  }

  @include media($min-md) {
    padding: 18px 40px;
  }

  @include media($min-lg) {
    padding: 20px 40px;
  }

  @include media($min-xl) {
    padding: 20px 0px;
  }

  & :global .help-menu {
    width: 182px;
  }
}

.site-logo {
  max-width: 75px;

  @include media($min-md) {
    max-width: 120px;
  }

  @include media($min-lg) {
    max-width: 150px;
  }
}

.need-help {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 10px;
  background: $white;
  font-size: rem(14);
}

.icon {
  margin-left: 16px;
  font-size: rem(18);
  color: $medium-light-gray;
  pointer-events: none;
}

.list {
  font-size: rem(14);
  text-align: center;
}

.list-item {
  border-bottom: 1px solid $dt-medium-light-gray;

  &:last-child {
    border: 0;
  }
}

.help-option {
  display: block;
  padding: 19px;
}

.help-option-icon {
  margin-right: 12px;
  color: $dark-gray;
}
