@mixin __FF_HISTORICAL_ORDERS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_USE_CVM_TEST_DATA($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ONE_REACH_MOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_RATES_AND_RULES_INFO__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DTCC_INLINE_APPLY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_EVENTSTREAM__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_RESERVATION_DETAILS__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_DRIVER_TYPES_UPDATE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DEBUG_CEC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_BOPIS_TO_QUOTE_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_HISTORICAL_ORDER_EDIT_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_LEGACY_ORDER_ACTIONS_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_RETURN_ALL_BLOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_fontawesome-svg-core.scss';
@import '../../shared/scss/_dt-base.scss';
@import '../../shared/scss/_normalize.scss';

html {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

*,
:after,
:before {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  color: $dark-gray;
  line-height: 1.45;
}

main {
  // these 2 are necessary to prevent overflow on mobile which causes the viewport
  // to crop the content.
  overflow-x: hidden;
  width: 100%;
  min-height: 700px;
  padding-top: 90px;

  @include media($min-sm) {
    overflow-x: visible;
    width: auto;

    padding-top: 111px;
  }
}

p {
  margin: 0 0 1rem;
}

input {
  border: 0;
  appearance: none;
  outline-offset: -2px;
}

input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h6 {
  margin: 0;
}

strong {
  font-weight: 800;
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: $dt-blue;
  cursor: pointer;
}

img {
  display: block;
  max-width: 100%;
}

img:-moz-loading {
  visibility: hidden;
}

button {
  padding: 0;
  border: 0;
  background: none;
  color: $dark-gray;
  cursor: pointer;
  background: transparent;
}

:focus {
  outline: 2px solid $orange;
}

input[type='checkbox'],
input[type='radio'] {
  &:focus + [class*='display'] {
    outline: 2px solid $orange;
  }
}

:global .js-focus-visible {
  :focus:not(.focus-visible) {
    outline: none;
  }

  input[type='radio']:focus:not(.focus-visible) + [class*='display'] {
    outline: none;
  }
}

.dt-link {
  font-size: 0.75rem;
  font-weight: bold;
  color: #3483de;
  background: none;
  padding: 0;
}

:global #popover-root {
  position: relative;
  z-index: 98;
}

:global #tooltip-root {
  position: relative;
  z-index: 100;
}
// Screen reader only content https://usability.yale.edu/web-accessibility/articles/links#hidden-link-text
:global .sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

:global .react-selectize {
  &.default.root-node {
    &.open {
      .react-selectize-control {
        .react-selectize-search-field-and-selected-values {
          .resizable-input {
            border-color: $orange;
          }
        }
        .react-selectize-toggle-button-container {
          border-color: $orange;
        }
      }
    }
    .react-selectize-control {
      .react-selectize-search-field-and-selected-values:focus-within
        + .react-selectize-toggle-button-container {
        border-color: $orange;
      }
    }
  }
}

// LivePerson chat button
:global .LPMcontainer {
  top: auto !important;
  bottom: 160px !important;
  margin-right: 0 !important;
}
