@mixin __FF_HISTORICAL_ORDERS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_USE_CVM_TEST_DATA($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ONE_REACH_MOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_RATES_AND_RULES_INFO__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DTCC_INLINE_APPLY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_EVENTSTREAM__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_RESERVATION_DETAILS__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_DRIVER_TYPES_UPDATE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DEBUG_CEC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_BOPIS_TO_QUOTE_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_HISTORICAL_ORDER_EDIT_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_LEGACY_ORDER_ACTIONS_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_RETURN_ALL_BLOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base.scss';

.header-container {
  display: flex;
  flex-direction: column;
  padding: 10px 20px 5px 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: $white;
  }

  .subtitle {
    width: 100%;
    padding-bottom: 10px;
    font-size: rem(14);
    color: $dt-medium-gray;
  }
}

.container {
  padding: 0 20px 20px;
}

.title {
  text-transform: uppercase;
  font-size: rem(14);
  font-weight: 800;
}

.close {
  font-size: rem(24);
  background: transparent;
  line-height: 1;
  margin-left: auto;
  margin-right: -10px;
  padding: 12px 13px;
}

:global .ReactModal__Body--open,
:global .ReactModal__Html--open {
  overflow: hidden;
}

:global .ReactModal__Overlay {
  z-index: 99;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  transition: background 0.3s;
}

:global .ReactModal__Overlay--after-open {
  background: rgba($dark-gray, 0.4) !important;
}

:global .ReactModal__Content {
  display: flex;
  flex-direction: column;
  top: 0px;
  right: 0px;
  left: 0px;
  max-width: 768px;
  margin: 0 auto;
  height: auto;
  min-height: 100%;
  background: $white;
  outline: 0;
  opacity: 0;
  transform: scale3d(0.83, 0.83, 1);
  transform-origin: center -150px;
  transition: opacity 0.37s, transform 0.41s cubic-bezier(0.37, 0.3, 0.02, 1.42);

  @include media($min-sm) {
    top: 60px;
    bottom: auto;
    margin-bottom: 40px;
    min-height: 0;
    border-radius: 3px;
    position: relative;
  }

  @include media($min-md) {
    top: 120px;
    margin-bottom: 60px;
  }
}

:global .ReactModal__Content--after-open {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
